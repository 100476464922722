<template>
  <div class="login">
    <div class="register-cont">
      <img
        class="register-cont-logo"
        src="../assets/login/logo_home.png"
        alt=""
      />
      <img
        class="register-cont-title"
        src="../assets/login/title_home.png"
        alt=""
      />
      <div class="register-cont-cont">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="rcc--ruleForm"
        >
          <el-form-item label="" prop="userName">
            <el-input
              class="input-height"
              prefix-icon="el-icon-user"
              placeholder="请输入账号"
              v-model="ruleForm.userName"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              style="height: 50px"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              show-password
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <div class="btn">
            <el-button
              style="width: 48%;height: 50px;"
              type="primary"
              @click="submitForm('ruleForm')"
              >登 录</el-button
            >
            <el-button style="width: 48%;height: 50px;"
              ><router-link class="link-type" :to="'/register'"
                >注 册</router-link
              ></el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import cross from "../utils/cross";
import axios from "axios";
import Vue from "vue";
import qs from "qs";

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      ruleForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.password = this.ruleForm.password.replace(/#/g, "%23");
          let url =
            process.env.VUE_APP_BASE_API +
            "/f/userInterface/login?userName=" +
            this.ruleForm.userName +
            "&password=" +
            this.ruleForm.password;

          axios.post(url).then((res) => {
            let userData = res.data;
            if (res.data) {
              const userString = JSON.stringify(res.data);
              localStorage.setItem("user", userString);
              if (res.data.userId) {
                this.$router.push({
                  //path: `/${cateItem.id}/${cateItem.name}`,
                  name: "HomeView",
                  params: { name: "logins" },
                });
              } else {
                this.$router.push({
                  //path: `/${cateItem.id}/${cateItem.name}`,
                  name: "HomeView",
                  params: { name: "noLogins" },
                });
              }
            } else {
              this.$message({
                message: "登录失败",
                type: "error",
              });
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw * 1920 / 1920);
  height: calc(100vh * 1080 / 1080);
  background-image: url("../assets/login/login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .register-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * 1230 / 1920);
    height: calc(100vh * 900 / 1080);
    .register-cont-logo {
      width: calc(100vw * 100 / 1920);
      margin-bottom: calc(100vh * 35 / 1080);
    }
    .register-cont-title {
      width: calc(100vw * 740 / 1920);
      // margin-bottom: calc(100vh * 40 / 1080);
    }
    .register-cont-cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100vw * 570 / 1920);
      height: calc(100vh * 360 / 1080);
      // background-color: (rgba($color: #ffffff, $alpha: 0.8));
      border-radius: calc(100vw * 20 / 1920);
      .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .rcc-register {
        font-size: calc(100vw * 20 / 1920);
        margin-bottom: calc(100vh * 10 / 1080);
      }
      .rcc--ruleForm {
        width: calc(100vw * 380 / 1920);
      }
    }
  }
}
</style>
<style>
.el-input--prefix .el-input__inner {
  height: 50px !important;
}
</style>